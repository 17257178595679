import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { RouterOutlet, RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ThemeStore, ToastStore } from '@lifestats/stores';
import { ToastComponent } from './components/toast/toast.component';
import { AuthStore } from '@lifestats/stores';
import { User } from 'firebase/auth';
import { sha256 } from '@noble/hashes/sha256';
import { bytesToHex } from '@noble/hashes/utils';
import { PATHS } from '@lifestats/interfaces';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    RouterModule,
    CommonModule,
    NgOptimizedImage,
    ToastComponent,
  ],
  template: `
    <div>
      <header class="sticky top-0 z-50">
        <!-- Navigation -->
        <nav
          class="navbar bg-base-100 shadow-lg"
          role="navigation"
          aria-label="Main navigation"
        >
          <div class="flex-1">
            <a
              class="btn btn-ghost text-xl text-primary"
              [routerLink]="'/' + PATHS.DASHBOARD"
              >Lifestats</a
            >
          </div>
          <div class="flex-none gap-3">
            @if (authStore.isAuthenticated()) {
            <a
              class="btn btn-ghost btn-circle"
              [routerLink]="'/' + PATHS.DASHBOARD + '/new'"
              title="Add new stat"
              aria-label="Add new stat"
              i18n
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </a>
            <div
              class="dropdown dropdown-end"
              role="navigation"
              aria-label="User menu"
            >
              <button
                tabindex="0"
                class="btn btn-ghost btn-circle avatar"
                aria-label="Open user menu"
              >
                @if (gravatarUrl()) {
                <img
                  ngSrc="gravatarUrl()!"
                  [alt]="userInitial()"
                  class="w-10 h-10 rounded-full"
                  (error)="gravatarError()"
                  fill
                />
                } @else {
                <div
                  class="w-10 h-10 rounded-full bg-primary text-primary-content grid place-content-center"
                  aria-label="User Initial"
                >
                  {{ userInitial() }}
                </div>
                }
              </button>
              <ul
                tabindex="0"
                class="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
                role="menu"
              >
                <li class="hidden">
                  <a
                    class="w-full text-left"
                    [routerLink]="'/' + PATHS.CATEGORIES"
                    role="menuitem"
                  >
                    <div class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                      Categories
                    </div>
                  </a>
                </li>
                <li>
                  <button
                    class="w-full text-left"
                    (click)="themeStore.toggleTheme()"
                    (keydown.enter)="themeStore.toggleTheme()"
                    role="menuitem"
                    aria-label="Toggle theme"
                    [attr.aria-checked]="themeStore.isDark()"
                  >
                    <div class="flex items-center gap-2">
                      @if (themeStore.isDark()) {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                      Light Mode } @else {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                        />
                      </svg>
                      Dark Mode }
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    class="w-full text-left"
                    (click)="signOut()"
                    (keydown.enter)="signOut()"
                    role="menuitem"
                  >
                    <div class="flex items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      Logout
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            }
          </div>
        </nav>
      </header>
      <main class="relative">
        <router-outlet></router-outlet>
        <app-toast />
      </main>
    </div>
  `,
})
export class AppComponent {
  readonly themeStore = inject(ThemeStore);
  readonly authStore = inject(AuthStore);
  readonly toastStore = inject(ToastStore);
  readonly gravatarUrl = signal<string | null>(null); //Not computed as we need to account for load 404
  readonly userInitial = computed<string>(() => {
    const user = this.authStore.user();
    return user?.email?.[0].toUpperCase() ?? '';
  });

  readonly PATHS = PATHS;

  constructor() {
    effect(() => {
      const user = this.authStore.user();
      this.updateGravatarUrl(user);
    });

    if (!environment.production) {
      console.info('appVersion:', environment.appVersion);
      console.info('buildDate:', environment.buildDate);
      console.info('buildUser:', environment.buildUser);
      console.info('gitHash:', environment.gitHash);
    }
  }

  private updateGravatarUrl(user: User | null): void {
    if (!user?.email) {
      this.gravatarUrl.set(null);
      return;
    }

    // Create hash of email
    const email = user.email.trim().toLowerCase();
    const hash = bytesToHex(sha256(email));

    // Set Gravatar URL with default to 404 (returns null if no Gravatar exists)
    this.gravatarUrl.set(`https://www.gravatar.com/avatar/${hash}?s=80&d=404`);
  }

  gravatarError(): void {
    this.gravatarUrl.set(null);
  }

  signOut(): void {
    this.authStore.signOut();
  }
}
