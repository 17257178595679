import { Routes } from '@angular/router';
import {
  AuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { PATHS } from '@lifestats/interfaces';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([PATHS.LOGIN]);
const redirectLoggedInToDashboard = () => redirectLoggedInTo([PATHS.DASHBOARD]);

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: PATHS.DASHBOARD,
    pathMatch: 'full',
  },
  {
    path: PATHS.LOGIN,
    canActivate: [AuthGuard],
    data: { authGuardPipe: () => redirectLoggedInToDashboard() },
    loadComponent: () =>
      import('./features/auth/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: PATHS.REGISTER,
    canActivate: [AuthGuard],
    data: { authGuardPipe: () => redirectLoggedInToDashboard() },
    loadComponent: () =>
      import('./features/auth/register/register.component').then(
        (m) => m.RegisterComponent
      ),
  },
  {
    path: PATHS.CATEGORIES,
    canActivate: [AuthGuard],
    data: { authGuardPipe: () => redirectUnauthorizedToLogin() },
    loadComponent: () =>
      import('./features/categories/categories.component').then(
        (m) => m.CategoriesComponent
      ),
  },
  {
    path: PATHS.DASHBOARD,
    canActivate: [AuthGuard],
    data: { authGuardPipe: () => redirectUnauthorizedToLogin() },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./features/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'new',
        loadComponent: () =>
          import('./features/stats/stat-form/stat-form.component').then(
            (m) => m.StatFormComponent
          ),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./features/stats/stat-detail/stat-detail.component').then(
            (m) => m.StatDetailComponent
          ),
      },
      {
        path: ':id/edit',
        loadComponent: () =>
          import('./features/stats/stat-form/stat-form.component').then(
            (m) => m.StatFormComponent
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: PATHS.DASHBOARD,
  },
];
