import { buildInfo } from './build-info';

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBaUANo9d_cBbz3tu3PZ5s3UXUkX9BknGU',
    authDomain: 'life-stats-dev.firebaseapp.com',
    databaseURL: 'https://life-stats-dev.firebaseio.com',
    projectId: 'life-stats-dev',
    storageBucket: 'life-stats-dev.firebasestorage.app',
    messagingSenderId: '549534692315',
    appId: '1:549534692315:web:2cbc1f92bac48762',
  },
  ...buildInfo,
};
