import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withProps,
  withState,
} from '@ngrx/signals';
import { computed, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export interface ThemeState {
  theme: 'light' | 'dark';
}

const initialState: ThemeState = {
  theme: 'light',
};

const THEME_KEY = 'lifestats-theme';

export const ThemeStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withProps(() => ({
    platformId: inject(PLATFORM_ID),
  })),
  withHooks({
    onInit({ platformId, ...store }) {
      if (isPlatformBrowser(platformId)) {
        // Initialize theme from localStorage or system preference
        const savedTheme = localStorage.getItem(THEME_KEY);
        if (savedTheme === 'dark' || savedTheme === 'light') {
          // Use saved preference
          patchState(store, { theme: savedTheme });
          document.documentElement.setAttribute('data-theme', savedTheme);
        } else {
          // Check system preference
          const prefersDark = window.matchMedia(
            '(prefers-color-scheme: dark)'
          ).matches;
          const systemTheme = prefersDark ? 'dark' : 'light';
          patchState(store, { theme: systemTheme });
          document.documentElement.setAttribute('data-theme', systemTheme);
          localStorage.setItem(THEME_KEY, systemTheme);
        }

        // Listen for system theme changes
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .addEventListener('change', (e) => {
            // Only update if there's no saved preference
            if (!localStorage.getItem(THEME_KEY)) {
              const newTheme = e.matches ? 'dark' : 'light';
              patchState(store, { theme: newTheme });
              document.documentElement.setAttribute('data-theme', newTheme);
              localStorage.setItem(THEME_KEY, newTheme);
            }
          });
      }
    },
  }),
  withComputed(({ theme }) => ({
    isDark: computed(() => theme() === 'dark'),
  })),
  withMethods(({ platformId, ...store }) => {
    return {
      setTheme(theme: ThemeState['theme']) {
        patchState(store, { theme });
        if (isPlatformBrowser(platformId)) {
          localStorage.setItem(THEME_KEY, theme);
          document.documentElement.setAttribute('data-theme', theme);
        }
      },

      toggleTheme() {
        const newTheme = store.theme() === 'light' ? 'dark' : 'light';
        this.setTheme(newTheme);
      },
    };
  })
);
